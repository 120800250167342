import ConversationListPanel from './ConversationListPanel'
import { Outlet } from 'react-router-dom' // useParams removed as it's not used
import styles from '../../styles/ChatInterface.module.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getUserConversations,
  getChatbot,
} from '../../services/API/ChatServices'
import ErrorCard from '../ErrorCard' // Import path adjusted as needed
import InformationCard from '../InformationCard'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const ChatInterface = () => {
  const { chatBotId, conversationId } = useParams()

  const backgroundImage = `url(${process.env.PUBLIC_URL}/images/linesTransparent.png)`

  //const [chatbotData, setChatbotData] = useState({})
  const [conversations, setConversations] = useState([]) // User conversations associated with the chatbot
  const [error, setError] = useState('')
  const [information, setInformation] = useState('')

  const [chatMessagesSaved, setChatMessagesSaved] = useState(true)

  const [chatbotData2, setChatbotData] = useState(null)

  let chatAssistantPicture = process.env.PUBLIC_URL + '/images/IvonneCircle.png'

  // Fetch user conversations when chatbotData is updated
  useEffect(() => {
    const fetchChatbot = async () => {
      try {
        if (chatBotId) {
          const response = await getChatbot(chatBotId)
          console.log('Response ', response)
          console.log('Response - success 1', response.success)
          if (response.success) {
            console.log('Response - success 2', response.success)
            console.log(response.payload)
            const chatbotData = response.payload
            setChatbotData(chatbotData) // Assuming you have a state setter for this
          } else {
            console.log('I am here in the error')
            setInformation(response.message)
          }
        }
      } catch (error) {
        console.log('I am here in the error 2')
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchChatbot()
  }, [chatBotId, conversationId]) // Removed conversations from dependencies to avoid infinite loop

  // Fetch user conversations when chatbotData is updated
  useEffect(() => {
    const fetchUserConversations = async () => {
      try {
        if (chatBotId) {
          const response = await getUserConversations(chatBotId)
          if (response.success) {
            setConversations(response.payload.reverse())
          } else {
            setInformation(response.message)
          }
        }
      } catch (error) {
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserConversations()
  }, [chatBotId, conversationId]) // Removed conversations from dependencies to avoid infinite loop

  const [isPanelVisible, setIsPanelVisible] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.box} style={{ backgroundImage }}>
        {error ? (
          <ErrorCard message={error} to={`/chatbot-dashboard`} name="RETURN" />
        ) : (
          <>
            {information !== '' && (
              <InformationCard
                title="NOTICE"
                information="Hey there! It looks like you haven't had a chat yet. No worries, starting a conversation is super easy. Just type your first message to the chat assistant, and you're on your way to an engaging dialogue. Let's get chatting!"
                toggleInformationVisibility={() => setInformation('')}
                imagePath={chatAssistantPicture}
              />
            )}
            {windowWidth <= 768 && (
              <MenuOpenIcon
                className={styles.hamburgerIcon}
                onClick={() => setIsPanelVisible(!isPanelVisible)}
              />
            )}
            {windowWidth > 768 || isPanelVisible ? (
              <ConversationListPanel
                className={styles.firstBox}
                conversationList={conversations}
                setChatMessagesSaved={setChatMessagesSaved}
                chatMessagesSaved={chatMessagesSaved}
                setPanelVisibility={() => setIsPanelVisible(!isPanelVisible)}
              />
            ) : null}
            {chatbotData2 !== null ? (
              <div className={styles.secondBox}>
                <Outlet
                  context={{
                    setChatMessagesSaved,
                    chatMessagesSaved,
                    chatbotData2,
                  }}
                />
                {/* Nested routes will render here */}
              </div>
            ) : (
              <div>LOADING</div>
            )}
          </>
        )}
      </div>
    </motion.div>
  )
}

export default ChatInterface
