// ChatbotDashboard.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styles from '../../styles/ChatbotDashboard.module.css'
import { getUserChatbots } from '../../services/API/ChatServices'
//import { setChatbotDataInSession } from '../../utils/SessionStorageUtil'
import ErrorCard from '../ErrorCard'
import InformationCard from '../InformationCard'
import Frame from '../Frame'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from '../../contexts/AuthContext'
import { deleteChatbot } from '../../services/API/ChatServices'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const ChatbotDashboardPage = () => {
  // Images for chatbot and information icon
  const chatbotImage = process.env.PUBLIC_URL + '/images/chatbot.png'

  // State management
  const [chatbots, setChatbots] = useState([])
  const [error, setError] = useState('')
  const [information, setInformation] = useState('')
  const [showInformation, setShowInformation] = useState(false)
  const [showErrorDelete, setShowErrorDelete] = useState(false)

  // Navigation hook for redirecting the user
  const navigate = useNavigate()

  const { user } = useAuth()

  // Function to handle selecting a chatbot
  const handleSelectChatbot = (chatbot) => {
    // Setting chatbot data in session storage
    // setChatbotDataInSession({
    //   chatbotID: chatbot.chatbot_id,
    //   chatbotName: chatbot.ChatbotModel.name,
    //   chatbotTitle: chatbot.ChatbotModel.title,
    //   chatbotDescription: chatbot.ChatbotModel.description,
    //   chatbotURL: chatbot.ChatbotModel.url,
    //   chatbotAvatar: chatbot.ChatbotModel.avatar,
    // })

    const chatbotDataSession = {
      chatbotID: chatbot.chatbot_id,
      chatbotName: chatbot.ChatbotModel.name,
      chatbotTitle: chatbot.ChatbotModel.title,
      chatbotDescription: chatbot.ChatbotModel.description,
      chatbotURL: chatbot.ChatbotModel.url,
      chatbotAvatar: chatbot.ChatbotModel.avatar,
    }
    // Navigating to chatbot interface
    navigate(`/chatbot-dashboard/${chatbot.chatbot_id}`, {
      state: chatbotDataSession,
    })
  }

  // Fetching user chatbots on component mount
  useEffect(() => {
    const fetchUserChatbots = async () => {
      try {
        const response = await getUserChatbots()
        if (response.success) {
          // Setting chatbots state if fetch is successful
          setChatbots(response.payload)
        } else if (
          !response.success &&
          response.message === 'No chatbots found'
        ) {
          // Handling the case where no chatbots are assigned to the user
          setInformation(
            "You currently don't have any chatbot assistants assigned. To get started, please reach out to the person in charge of chatbot assignments in your organization. If you have any questions or need further assistance, feel free to contact our support team. We're here to help you every step of the way.",
          )
          setShowInformation(true)
        }
      } catch (error) {
        // Handling errors during data fetch
        console.error('Error fetching chatbots:', error)
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserChatbots()
  }, [])

  // Function to hide information card
  const handleInformationVisibility = () => {
    setShowInformation(false)
  }

  const handleErrorDeleteVisibility = () => {
    setShowErrorDelete(false)
  }

  const isCompanyAdmin = user.role === 'company_admin'

  const handleDeleteChatbot = async (chatbotId) => {
    try {
      const response = await deleteChatbot(chatbotId)
      if (
        response.success &&
        response.message === 'Chatbot deleted successfully'
      ) {
        //chatbot deleted successfully
        // Filter out the deleted chatbot from the chatbots array
        const updatedChatbots = chatbots.filter(
          (bot) => bot.chatbot_id !== chatbotId,
        )
        setChatbots(updatedChatbots) // Update the state with the new array
        console.log('Chatbot deleted successfully')
      } else {
        //problem
        setShowErrorDelete(true)
      }
    } catch (error) {
      setShowErrorDelete(true)
    }
  }

  const squashMenuItems = [
    {
      id: 1,
      label: 'Edit',
      icon: <EditNoteIcon className={styles.icon} />,
      action: (chatbotId) => {
        const chatbotToEdit = chatbots.find(
          (bot) => bot.chatbot_id === chatbotId,
        )

        if (chatbotToEdit) {
          navigate('/update-chatbot', {
            state: { chatbot: chatbotToEdit },
          })
        }
      },
    },
    {
      id: 2,
      label: 'Delete',
      icon: <DeleteIcon className={styles.icon} />,
      action: (chatbotId) => {
        handleDeleteChatbot(chatbotId)
      },
    },
  ]

  // Rendering logic based on different states
  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.container}>
        <div className={styles.pageTitle}>Chatbot Dashboard</div>
        <hr className={styles.titleLine} />
        {error ? (
          <ErrorCard message={error} action="RELOAD" name="Retry" />
        ) : showInformation ? (
          <InformationCard
            title="Notice"
            information={information}
            toggleInformationVisibility={handleInformationVisibility}
            imagePath={chatbotImage}
          />
        ) : (
          <div className={styles.dashboard}>
            {chatbots.map((bot) => (
              <Frame
                key={bot.chatbot_id}
                onClick={() => handleSelectChatbot(bot)}
                data={{
                  name: bot.ChatbotModel.name,
                  title: bot.ChatbotModel.title,
                  description: bot.ChatbotModel.description,
                  avatar: `${process.env.PUBLIC_URL}/images/avatars/${bot.ChatbotModel.avatar}.png`,
                }}
                menuItems={isCompanyAdmin ? squashMenuItems : []}
                chatbotId={bot.chatbot_id}
              />
            ))}
          </div>
        )}
        {showErrorDelete && (
          <InformationCard
            title="Notice"
            information="There was a problem to delete the chatbot."
            toggleInformationVisibility={handleErrorDeleteVisibility}
            imagePath={chatbotImage}
          />
        )}
      </div>
    </motion.div>
  )
}

export default ChatbotDashboardPage
