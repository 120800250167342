import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from '../styles/ErrorCard.module.css'

const ErrorCard = ({
  message,
  to = '/',
  name,
  action = 'REDIRECT',
  handleBtnClick,
}) => {
  const navigate = useNavigate()

  // Function to navigate and reload for the same path
  const reloadPage = () => {
    navigate(0) // React Router v6 method to reload the current page
  }

  return (
    <div className={styles.backdrop}>
      <div className={styles.error_card}>
        <div
          className={styles.error_animation}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/computerPixel.png)`,
          }}
        >
          {/* Animated error icon or image */}
        </div>
        <h2>Error Occurred</h2>
        <p>{message || 'Something went wrong. Please try again later.'}</p>
        {action === 'REDIRECT' && (
          <Link to={to} className={styles.return_button}>
            {name}
          </Link>
        )}
        {action === 'RELOAD' && (
          <button onClick={reloadPage} className={styles.return_button}>
            {name}
          </button>
        )}
        {action === 'CONFIRM' && (
          <button onClick={handleBtnClick} className={styles.return_button}>
            {name}
          </button>
        )}
      </div>
    </div>
  )
}

export default ErrorCard
