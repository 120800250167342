import styles from '../../styles/AddEditChatbotPage.module.css'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { registerChatbot, updateChatbot } from '../../services/API/ChatServices'
import GeneralButton from '../GeneralButton'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const AddEditChatbotPage = () => {
  const location = useLocation()
  const chatbotData = location.state?.chatbot
  const isEditMode = Boolean(chatbotData)

  const navigate = useNavigate()

  const [submitError, setSubmitError] = useState('')
  const [generalError, setGeneralError] = useState('')

  const backgroundImage = `url(${process.env.PUBLIC_URL}/images/linesTransparent.png)`
  const [formState, setFormState] = useState({
    chatbotName: '',
    expertiseArea: '',
    chatbotDescription: '',
    chatbotPrompt: '',
    urlLink: '',
    selectedAvatar: '',
  })

  useEffect(() => {
    if (isEditMode) {
      setFormState({
        chatbotName: chatbotData.ChatbotModel.name,
        expertiseArea: chatbotData.ChatbotModel.title,
        chatbotDescription: chatbotData.ChatbotModel.description,
        chatbotPrompt: chatbotData.ChatbotModel.chatbot_prompt,
        selectedAvatar: chatbotData.ChatbotModel.avatar,
      })
    } else {
      setFormState({
        chatbotName: '',
        expertiseArea: '',
        chatbotDescription: '',
        chatbotPrompt: '',
        urlLink: '',
        selectedAvatar: '',
      })
    }
  }, [isEditMode])

  const handleChange = (fieldName) => (e) => {
    const inputValue = e.target.value
    const limit = charLimits[fieldName]
    if (!limit || inputValue.length <= limit) {
      setFormState((prevState) => ({ ...prevState, [fieldName]: inputValue }))
    }
  }

  const handleAvatarSelect = (avatarName) => {
    setFormState((prevState) => ({ ...prevState, selectedAvatar: avatarName }))
  }

  const avatars = [
    { name: 'IvonneCircle', label: 'Ivonne' },
    { name: 'BeeCircle', label: 'Bee' },
    { name: 'SophieCircle', label: 'Sophie' },
    { name: 'LamaCircle', label: 'Lama' },
    { name: 'MaryCircle', label: 'Mary' },
    { name: 'OwlyCircle', label: 'Owl' },
    { name: 'SamCircle', label: 'Sam' },
    { name: 'SheilaCircle', label: 'Sheila' },
    { name: 'BottyCircle', label: 'Botty' },
    { name: 'RobertCircle', label: 'Robert' },
    { name: 'HeartCircle', label: 'Heart' },
    { name: 'WilliamCircle', label: 'William' },
  ]

  const charLimits = {
    chatbotName: 15, // Example limit for chatbot name
    expertiseArea: 70, // Example limit for expertise area
    chatbotDescription: 220, // Example limit for chatbot description
    urlLink: 150, // Example limit for URL link
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isEditMode) {
      if (
        !formState.chatbotName ||
        !formState.expertiseArea ||
        !formState.chatbotDescription ||
        !formState.urlLink ||
        !formState.selectedAvatar
      ) {
        setGeneralError('Please fill in all fields and select avatar.')
        return
      }
      try {
        const response = await registerChatbot(formState)

        navigate('/chatbot-dashboard')
      } catch (err) {
        // Handle  errors
        setSubmitError(
          err.message || 'An error occurred during chatbot registration.',
        )
      }
    } else {
      if (
        !formState.chatbotName ||
        !formState.expertiseArea ||
        !formState.chatbotDescription ||
        !formState.selectedAvatar
      ) {
        setGeneralError('Please fill in all fields and select avatar.')
        return
      }
      try {
        const response = await updateChatbot(formState, chatbotData.chatbot_id)
        navigate('/chatbot-dashboard')
      } catch (err) {
        // Handle  errors
        setSubmitError(
          err.message || 'An error occurred during chatbot update.',
        )
      }
    }
  }

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.pageBackground} style={{ backgroundImage }}>
        <div className={styles.container}>
          <div className={styles.text}>
            {isEditMode
              ? `Update ${chatbotData.ChatbotModel.name} Chatbot`
              : 'Register New Chatbot'}
          </div>
          {submitError && <p className={styles.errorMessage}>{submitError}</p>}
          {generalError && (
            <p className={styles.errorMessage}>{generalError}</p>
          )}
          <form className={styles.form} onSubmit={handleSubmit}>
            <textarea
              rows="3"
              value={formState.expertiseArea}
              placeholder="Chatbot's Expertise Area (max. characters 70)"
              onChange={handleChange('expertiseArea')}
            />
            <input
              text="text"
              value={formState.chatbotName}
              placeholder="Chatbot Name (max. characters 15)"
              onChange={handleChange('chatbotName')}
            />
            <textarea
              rows="8"
              value={formState.chatbotDescription}
              placeholder="Chatbot Description (max. characters 220)"
              onChange={handleChange('chatbotDescription')}
            />
            <textarea
              rows="3"
              value={formState.chatbotPrompt}
              placeholder="Chatbot Prompt (max. characters 220)"
              onChange={handleChange('chatbotPrompt')}
            />
            {!isEditMode ? (
              <input
                text="url"
                value={formState.urlLink}
                placeholder="Chatbot URL"
                onChange={handleChange('urlLink')}
              />
            ) : (
              ''
            )}
            <div className={styles.avatarContainer}>
              {avatars.map((avatar) => (
                <div
                  key={avatar.name}
                  className={`${styles.avatar} ${
                    formState.selectedAvatar === avatar.name
                      ? styles.selected
                      : ''
                  }`}
                  onClick={() => handleAvatarSelect(avatar.name)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/avatars/${avatar.name}.png`}
                    alt={avatar.label}
                  />
                </div>
              ))}
            </div>
            <GeneralButton
              type="submit"
              text={isEditMode ? 'Update' : 'Register'}
            />
          </form>
        </div>
      </div>
    </motion.div>
  )
}
export default AddEditChatbotPage
