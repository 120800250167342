import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { Fragment, useEffect, useState, useRef } from 'react'
import styles from '../../styles/ChatWindow.module.css'
import {
  getChatMessages,
  updateChatMessages,
  createNewChat,
  sendPromptToChatbot,
  generateTitle,
} from '../../services/API/ChatServices'
import ErrorCard from '../ErrorCard'
//import { getChatbotDataFromSession } from '../../utils/SessionStorageUtil'
import { encodingForModel } from 'js-tiktoken'
import SendButton from '../SendButton'
import ReactMarkdown from 'react-markdown'
import DOMPurify from 'dompurify'
import socketIOClient from 'socket.io-client'

const ChatWindow = () => {
  const imagePathUser =
    process.env.PUBLIC_URL + '/images/avatars/avatarUser2.png'
  // useParams lets us grab info from the webpage's address, like IDs.
  const { chatBotId, conversationId } = useParams()

  //Retrieve from the outlet context
  const { setChatMessagesSaved, chatMessagesSaved, chatbotData2 } =
    useOutletContext()

  const [chatbotInSession, setChatbotInSession] = useState(chatbotData2)

  useEffect(() => {
    setChatbotInSession(chatbotData2)
  }, [chatbotData2])

  const imagePath = chatbotInSession.avatar
    ? `${process.env.PUBLIC_URL}/images/avatars/${chatbotInSession.avatar}.png`
    : ''

  // navigate is like a GPS for our website; it helps us move around.
  const navigate = useNavigate()

  const messagesContainerRef = useRef(null)

  const messageTextAreaRef = useRef(null)

  const socket = socketIOClient('https://chatbot3.humanasset.com') //flowise url

  //https://chatbot3.humanasset.com/api/v1/prediction/c7c1a2d8-6ff0-4c0a-aa5a-80c4adb5dfcd

  const [socketIOClientId, setSocketIOClientId] = useState('')

  socket.on('connect', () => {
    setSocketIOClientId(socket.id)
  })

  // useState is like a personal notepad. It keeps track of info while using the chat.
  const [chatMessages, setChatMessages] = useState([]) // user's chat messages associated to a particular conversation and chatbot
  const [isLoading, setIsLoading] = useState(true) // while the user' chat messages are being fetched loading is set to true
  const [message, setMessage] = useState('') // it is user's prompt from the input field and it is cleared once there was a successful response to sent the promt to chatbot. In the event that the resuest failed, we still keep the message to allow user to retry sending the prompot again without asking him to type the prompt again
  const [textAreaValue, setTextAreaValue] = useState('') // it is also user's prompt from the input field which is set as a value of the input field and this one is cleared once the user has sent the prompt so the input field shows the placeholder again
  const [information, setInformation] = useState('') // information of the response -> the api call was successfull but perhaps no data was found
  const [errorRetrievingMessages, setErrorRetrievingMessages] = useState(false) // string containing the error message when retrieving user's messages fails at any point
  const [error, setError] = useState('') // string containing the error message when sending a prompt to chatbot
  const [previousChatMessagesSent, setPreviousChatMessagesSent] =
    useState(false) // indicator if new messages added to the conversion have been saved or not yet
  const [chatbotData, setChatbotData] = useState({}) //the data about chatbot being the receiver
  const [isChatbotTyping, setIsChatbotTyping] = useState(false) // indicator if chatbot is 'typing' meaning that the response still not come
  const [sendBtnActive, setSendBtnActive] = useState(false) // if there is no prompt in the input field the send button should be disabled
  const [saveButtonActive, setSaveButtonActive] = useState(false) // the save button should be only active if there is at least one new message in the conversation which was not saved yet
  const [errorSavingChatMessages, setErrorSavingChatMessages] = useState(false) // indicator if there was an error saving the chat messages
  //const [chatMessagesSaved, setChatMessagesSaved] = useState(true) // indicator if messages have been saved which serves different purposes than the error above -> expecially when switching between conversations

  const [isSending, setIsSending] = useState(false) //the response from the API flowise
  const [responseReceived, setResponseReceived] = useState(false) //
  const [avatarImagePath, setAvatarImagePath] = useState(imagePath)
  const [tokensChat, setTokensChat] = useState({ in: 0, out: 0, total: 0 })

  // Another robot that fetches messages for our chat when we get back to historical chat -> it fetches data only if the conversation id is present or if the conversation is changes
  useEffect(() => {
    //RESET THE STATE
    setIsLoading(true)
    setMessage('')
    setTextAreaValue('')
    setErrorRetrievingMessages(false)
    setError('')
    setPreviousChatMessagesSent(false)
    setChatbotData({})
    setIsChatbotTyping(false)
    setSendBtnActive(false)
    setSaveButtonActive(false)
    setErrorSavingChatMessages(false)
    setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true
    setResponseReceived(false)
    setIsSending(false)
    setTokensChat({ in: 0, out: 0, total: 0 })

    const fetchChatMessages = async () => {
      try {
        console.log(
          'CONVERSATION: ',
          conversationId,
          ' CHATBOT ID: ',
          chatBotId,
        )
        const response = await getChatMessages(chatBotId, conversationId)
        console.log('I am fetching messages')
        if (response.success) {
          console.log('TRY SUCCESS')
          setChatMessages(JSON.parse(response.payload.messages))
        } else if (
          !response.success &&
          response.message === 'No messages found'
        ) {
          setInformation(response.message)
        }
      } catch (error) {
        setErrorRetrievingMessages(error)
      }
      setIsLoading(false)
    }

    if (conversationId) {
      setIsLoading(true)

      fetchChatMessages(chatBotId, conversationId)
    } else {
      // If there is no conversationId, reset the conversation state
      setChatMessages([
        {
          sender: 'chatbot',
          message: chatbotInSession.chatbot_prompt
            ? chatbotInSession.chatbot_prompt
            : 'Hello, how can I assist you today?',
        },
      ])
    }
  }, [conversationId, chatBotId])

  //This robot checks if we have chatbot data saved (Loads chatbot data from session storage when the component mounts) and if not, it takes us to the dashboard.
  // useEffect(() => {
  //   const storedChatbotData = getChatbotDataFromSession()
  //   if (storedChatbotData && storedChatbotData.chatbotID) {
  //     setChatbotData(storedChatbotData)

  //     // Assuming storedChatbotData contains the avatar name, e.g., 'IvonneCircle'
  //     if (storedChatbotData.chatbotAvatar) {
  //       const imagePath = `${process.env.PUBLIC_URL}/images/${storedChatbotData.chatbotAvatar}.png`
  //       setAvatarImagePath(imagePath)
  //     }
  //   } else {
  //     // Redirect to dashboard if chatbot data is not available
  //     navigate('/chatbot-dashboard')
  //   }
  // }, [navigate])

  // This robot warns us if we're about to leave the page but haven't saved our messages.
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Check if there's any unsaved messages
      if (!chatMessagesSaved) {
        // Prevent the default unload behavior and show a warning message
        e.preventDefault()
        e.returnValue =
          'You have unsent messages. Are you sure you want to leave?'
      }
    }

    // Add event listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Remove event listener on cleanup
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [chatMessagesSaved]) // Depend on the 'chatMessagesSaved' state

  useEffect(() => {
    if (messagesContainerRef.current) {
      const messagesContainer = messagesContainerRef.current
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }, [chatMessages])

  useEffect(() => {
    if (messageTextAreaRef.current) {
      if (textAreaValue === '') {
        messageTextAreaRef.current.style.height = '5rem' // Reset to original height
      } else {
        messageTextAreaRef.current.style.height = 'auto'
        messageTextAreaRef.current.style.height = `${messageTextAreaRef.current.scrollHeight}px`
      }
    }
  }, [textAreaValue])

  useEffect(() => {
    // Call handleSaveConversation whenever chatMessages changes
    if (responseReceived) {
      handleSaveConversation()
      setResponseReceived(false)
    }
  }, [responseReceived])

  // handlePromptChange updates what we're typing into the chat.
  const handlePromptChange = (event) => {
    const textarea = event.target
    textarea.style.height = '5rem' // Reset to original height
    textarea.style.height = `${Math.min(
      textarea.scrollHeight,
      7.5 * parseFloat(getComputedStyle(textarea).fontSize),
    )}px` // Adjust height based on content

    setMessage(event.target.value.replace(/\n+$/, ''))
    setTextAreaValue(event.target.value)
    setSendBtnActive(event.target.value.length > 0 && !isSending)
  }

  // buildRequestBody prepares our message to be sent to the chatbot.
  const buildRequestBody = (messages, messageToBeSent, isRetry = false) => {
    if (messages.length > 0) {
      if (isRetry) {
        let clonedMessages = JSON.parse(JSON.stringify(messages))
        clonedMessages.pop()
        const history = clonedMessages.map((message) => ({
          type: message.sender === 'chatbot' ? 'apiMessage' : 'userMessage',
          message: DOMPurify.sanitize(message.message),
        }))
        return {
          question: DOMPurify.sanitize(messageToBeSent),
          history: history,
        }
      } else {
        const history = messages.map((message) => ({
          type: message.sender === 'chatbot' ? 'apiMessage' : 'userMessage',
          message: DOMPurify.sanitize(message.message),
        }))
        return {
          question: DOMPurify.sanitize(messageToBeSent),
          history: history,
        }
      }
    }
  }

  //IF IT IS ONE LINE of RETURN STATETMENT THEN YOU CAN SKIP RETURN KEYWORD AND THEN USE PARENTHESIS () in arrow funtion

  // handlePromptSend is like pressing the "send" button in a chat.
  const handlePromptSend = async (isRetry = false) => {
    setTextAreaValue('')
    setSendBtnActive(false)
    setIsSending(true)

    if (message !== '' && message !== null) {
      const encoder = encodingForModel('gpt-4')
      const newTokens = encoder.encode(message)

      setTokensChat((prevState) => ({
        ...prevState,
        out: prevState.out + newTokens.length,
        total: prevState.total + newTokens.length,
      }))

      if (!isRetry) {
        // Add user's message only if it's not a retry attempt
        const userMessage = { sender: 'user', message: message }
        setChatMessages((prevMessages) => [...prevMessages, userMessage])
        setSaveButtonActive(true)
      }

      setIsChatbotTyping(true)

      // Build request body based on conversation context
      let request =
        conversationId && !previousChatMessagesSent
          ? buildRequestBody(chatMessages, message, isRetry)
          : { question: message }
      try {
        const response = await sendPromptToChatbot(
          request,
          chatbotInSession.url, //chatbotData
        )

        if (response) {
          const encoder = encodingForModel('gpt-4')
          const newTokens = encoder.encode(response.text)
          setTokensChat((prevState) => ({
            ...prevState,
            in: prevState.in + newTokens.length,
            total: prevState.total + newTokens.length,
          }))

          const apiMessage = {
            sender: 'chatbot',
            message: DOMPurify.sanitize(response.text),
          }
          setChatMessages((prevMessages) => [...prevMessages, apiMessage])
          setError('')
          setPreviousChatMessagesSent(
            conversationId && !previousChatMessagesSent ? true : false,
          )
          setChatMessagesSaved(false)
          setSendBtnActive(true)
          setResponseReceived(true)
        } else {
          setError(`There was an error while sending your message. ${error}`)
        }
      } catch (error) {
        setError(`There was an error while sending your message. ${error}`)
      } finally {
        setIsChatbotTyping(false)
        setIsSending(false)
      }
    }
  }

  // handleRetrySendMessage lets us try sending a message again if something went wrong.
  const handleRetrySendMessage = () => {
    handlePromptSend(true)
  }

  // handleSaveConversation saves our chat so we can look at it later.
  const handleSaveConversation = async () => {
    let response
    const messages = JSON.stringify(chatMessages)
    const messageObject = { messages: messages }
    let title // Declare title variable outside the try...catch blocks
    try {
      if (conversationId) {
        response = await updateChatMessages(
          chatBotId,
          conversationId,
          messageObject,
          tokensChat,
        )
      } else {
        console.log('I am in the else statement first')
        const firstMessage = chatMessages.find(
          (message) => message.sender === 'user',
        )?.message

        console.log('The first message is: ', firstMessage)

        //send first user's message to title geny
        try {
          // Attempt to generate a title
          const res = await generateTitle({ question: firstMessage })
          console.log('In the res and the title is: ', res.text)
          title = res.text // Use the generated title
        } catch (generateTitleError) {
          // Fallback to manual title generation if generateTitle fails
          console.log('In the else, generating manually the title')
          title = chatMessages
            .find((message) => message.sender === 'user')
            ?.message.split(' ')
            .slice(0, 3)
            .join(' ')
            .concat('...')
        }
        response = await createNewChat(
          chatBotId,
          title,
          messageObject,
          tokensChat,
        )
      }

      // Check response
      if (response?.status === 200) {
        console.log('Response after saving to db')
        const successMessage = conversationId
          ? 'Chat messages updated successfully'
          : 'Chat messages created successfully'
        if (response.data.success) {
          //alert(successMessage)
          setChatMessagesSaved(true)
          if (!conversationId) {
            navigate(
              `/chatbot-dashboard/${chatBotId}/${response.data.payload?.conversation_id}`,
            )
          }
        } else {
          setErrorSavingChatMessages(true)
        }
      } else {
        setErrorSavingChatMessages(true)
      }
    } catch (error) {
      console.log('i AM LANDING IN THE CATCH')
      setErrorSavingChatMessages(true)
    } finally {
      setSaveButtonActive(false)
    }
  }

  const formatMessage = (message) => {
    // Regular expression to identify URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g

    return message.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line.split(urlRegex).map((part, index) => {
          // Check if the part is a URL
          if (part.match(urlRegex)) {
            return (
              <a
                className={styles.link}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {part}
              </a>
            )
          } else {
            return part
          }
        })}
        <br />
      </Fragment>
    ))
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter', the Shift key is not held down, and not currently sending a message
    if (event.key === 'Enter' && !event.shiftKey && !isSending) {
      event.preventDefault() // Prevents the default action (new line)
      // Call the function to send the message
      handlePromptSend()
    }
  }

  return (
    <div className={styles.chatWindow}>
      {isLoading && conversationId ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.messagesContainer} ref={messagesContainerRef}>
            <>
              {errorRetrievingMessages ? (
                <ErrorCard
                  message="An unexpected error occurred while retrieving the previous chat messages. Please try again later."
                  to={`/chatbot-dashboard/${chatBotId}/${conversationId}`}
                  name="RETRY"
                />
              ) : (
                <>
                  <div className={styles.parentContainer}>
                    <span className={styles.chatbotTitle}>
                      {chatbotInSession.title}
                    </span>
                  </div>
                  {Array.isArray(chatMessages) &&
                    chatMessages.map((message, index) => (
                      <div
                        key={index}
                        className={
                          message.sender === 'chatbot'
                            ? styles.chatbotMessage
                            : styles.userMessage
                        }
                      >
                        <img
                          className={styles.avatar}
                          alt="avatar"
                          src={
                            message.sender === 'chatbot'
                              ? avatarImagePath
                              : imagePathUser
                          }
                        />
                        {message.sender === 'chatbot' ? (
                          <span className={styles.message}>
                            <ReactMarkdown
                              components={{
                                // Inline customization for links
                                a: ({ node, ...props }) => (
                                  <a
                                    {...props}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.link}
                                  />
                                ),
                              }}
                            >
                              {message.message}
                            </ReactMarkdown>
                          </span>
                        ) : (
                          <span className={styles.message}>
                            <ReactMarkdown
                              components={{
                                // Inline customization for links
                                a: ({ node, ...props }) => (
                                  <a
                                    {...props}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.link}
                                  />
                                ),
                              }}
                            >
                              {message.message}
                            </ReactMarkdown>
                          </span>
                        )}
                      </div>
                    ))}
                  {isChatbotTyping && (
                    <div className={styles.chatbotMessage}>
                      <img
                        className={styles.avatar}
                        alt="avatar"
                        src={avatarImagePath}
                      />

                      <span className={styles.dots}>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </span>
                    </div>
                  )}
                  {error && (
                    <button
                      className={styles.retryBtn}
                      onClick={handleRetrySendMessage}
                    >
                      Retry <br /> {error}
                    </button>
                  )}
                  {errorSavingChatMessages && (
                    <ErrorCard
                      message="An unexpected error occurred while trying to save your conversation"
                      name="OK"
                      action="CONFIRM"
                      handleBtnClick={() => setErrorSavingChatMessages(false)}
                    />
                  )}
                </>
              )}
            </>
          </div>
          <div className={styles.footer}>
            <textarea
              ref={messageTextAreaRef}
              className={styles.inputField}
              onChange={handlePromptChange}
              value={textAreaValue}
              placeholder="Type here..."
              onKeyDown={handleKeyDown}
            />
            <SendButton onClick={handlePromptSend} disabled={!sendBtnActive} />
          </div>
        </>
      )}
    </div>
  )
}

export default ChatWindow

// <button
//   className={
//     sendBtnActive ? styles.sendButton : styles.sendButtonInactive
//   }
//   onClick={() => handlePromptSend()}
//   disabled={!sendBtnActive}
// >
//   SEND
// </button>
// <button
//   className={
//     saveButtonActive ? styles.saveButton : styles.saveButtonInactive
//   }
//   onClick={handleSaveConversation}
//   disabled={!saveButtonActive}
// >
//   SAVE
// </button>
