import { Link } from 'react-router-dom';
import { useClickAway } from '@uidotdev/usehooks';
import styles from '../../styles/HamburgerMenu.module.css';
import { useAuth } from '../../contexts/AuthContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const HamburgMenu = ({ toggelVisibilityMenu, initials }) => {
  const { user, logout } = useAuth();

  const ref = useClickAway(() => {
    toggelVisibilityMenu();
  });

  const handleLinkClick = () => {
    toggelVisibilityMenu();
  };

  return (
    <div ref={ref} className={styles.dropdown}>
      <div className={styles.userDetails}>
        <div className={styles.userCircle}>{initials}</div>
        <div className={styles.userInfo}>
          <div className={styles.userName}>
            {`${user.name} ${user.surname}`}
          </div>
          <div className={styles.userEmail}>{user.email}</div>
        </div>
      </div>
      <div className={styles.divider}></div>

      {user?.role === 'web_admin' && (
        <>
          <a
            href="https://chat.aihub-lab.eu/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
          >
            Chatbot Lab
          </a>
          <div className={styles.divider}></div>
          <Link to="/register-chatbot" onClick={handleLinkClick}>
            Register chatbot
          </Link>
          <div className={styles.divider}></div>
          <Link to="/company-management" onClick={handleLinkClick}>
            Company Management
          </Link>
          <div className={styles.divider}></div>
          {/* Any other routes for the web admin */}
        </>
      )}
      {user?.role === 'company_admin' && (
        <>
          <a
            href="https://chat.aihub-lab.eu/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
          >
            Chatbot Lab
          </a>
          <div className={styles.divider}></div>
          <Link to="/register-chatbot" onClick={handleLinkClick}>
            Register chatbot
          </Link>
          <div className={styles.divider}></div>
          <Link to="/chatbot-dashboard" onClick={handleLinkClick}>
            Chatbot Dashboard
          </Link>
          <div className={styles.divider}></div>
          <Link to="/user-management" onClick={handleLinkClick}>
            User Mangement
          </Link>
          <div className={styles.divider}></div>
          <Link to="/chat-token-tracker" onClick={handleLinkClick}>
            Chat Token Tracker
          </Link>
          <div className={styles.divider}></div>
        </>
      )}
      {user?.role === 'user' && (
        <>
          <Link to="/chatbot-dashboard" onClick={handleLinkClick}>
            Chatbot Dashboard
          </Link>
          <div className={styles.divider}></div>
        </>
      )}
      <button
        className={styles.logoutButton}
        onClick={() => {
          handleLinkClick();
          logout();
        }}
      >
        <ExitToAppIcon style={{ marginRight: '8px' }} />
        Log out
      </button>
    </div>
  );
};
export default HamburgMenu;

// <Link to="/my-profile" onClick={handleLinkClick}>
//         My Profile
//       </Link>
//       <div className={styles.divider}></div>
